<template>
  <div>
    <div v-if="dataListsSurCharge.length > 0" class="mt-3">
      <p class="font-weight-bold f-size-14 main-color">
        {{ $t("parcelShopFee") }}
      </p>
      <div
        v-for="(item, index) in dataListsSurCharge"
        :key="index"
        class="item-container"
      >
        <div class="d-flex">
          <div class="mr-3">
            <div class="logo-image-container text-center">
              <img :src="item.packageImageUrl" class="logo-img" />
            </div>
          </div>
          <div class="d-flex justify-content-center flex-column mr-3">
            <p class="f-size-14 font-weight-bold mb-2">
              {{ lang === "th" ? item.packageName.th : item.packageName.en }}
            </p>
            <div class="describe-container">
              {{
                lang === "th" ? item.packageDetail.th : item.packageDetail.en
              }}
            </div>
          </div>
        </div>
        <div>
          <h4 class="text-right">{{ item.packageSurcharge }}</h4>
          <p class="text-right">{{ $t("bath") }}</p>
          <b-button size="sm" variant="to-map" @click="setShowMap(true)" class="rounded-pill text-right mt-2">
            {{ $t("จุดบริการเคอรี่ เอ็กซ์เพรส") }}
          </b-button>
        </div>
      </div>
    </div>
    <div v-if="dataListsD2D.length > 0" class="mt-3">
      <p class="font-weight-bold f-size-14 main-color">
        {{ $t("pickupRequestFee") }}
      </p>
      <div
        v-for="(item, index) in dataListsD2D"
        :key="index"
        class="item-container"
      >
        <div class="d-flex">
          <div class="mr-3">
            <div class="logo-image-container text-center">
              <img :src="item.packageImageUrl" class="logo-img" />
            </div>
          </div>
          <div class="d-flex justify-content-center flex-column mr-3">
            <p class="f-size-14 font-weight-bold mb-2">
              {{ lang === "th" ? item.packageName.th : item.packageName.en }}
            </p>
            <div class="describe-container">
              {{
                lang === "th" ? item.packageDetail.th : item.packageDetail.en
              }}
            </div>
          </div>
        </div>
        <div>
          <h4>{{ item.packageD2DPrice }}</h4>
          <p class="text-center">{{ $t("bath") }}</p>
          <b-button size="sm" variant="to-map" @click="setShowMap(true)" class="rounded-pill text-right mt-2">
            {{ $t("เรียกรถเข้ารับพัสดุ") }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name:"PriceSection",
props: {
    dataListsSurCharge: {
        type: Array,
        required: true
    },
    dataListsD2D: {
        type: Array,
        required: true
    },
    lang: {
        type: String,
        required: true
    },
    setShowMap: {
        type: Function,
        required: false
    },    
}
}
</script>
<style lang="scss" scoped>
.item-container {
  background-color: white;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
  align-items: center;
  border-radius: 8px;
}

.describe-container {
  border-radius: 10px;
  background-color: #ebeff0;
  font-size: 12px;
  padding: 4px;
  min-width: 150px;
  text-align: center;
}

.logo-img {
  width: 80px;
  height: 80px;
}

p {
  margin-bottom: 0;
}

h4 {
  color: #f37021;
}

.btn-to-map{
  border: 1px solid #f37021 !important;
  color: #f37021;
  background-color: #fff !important;
}
.btn-to-map:active{
  border: 1px solid #f37021 !important;
  color: #fff;
  background-color: #f37021 !important;
}

@media only screen and (max-width: 374px) {
  .describe-container {
    min-width: 0;
  }
}
</style>
